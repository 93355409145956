
<script>

import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";
import VacancyHighlight from "@/mvp/funnel/VacancyHighlight";

import store from '@/store/store';

export default {
  name: "NewVacancy",
  components: {
        Navbar,
        Footer,
        VacancyHighlight,
      },
  data() {
    return {
      resumes:[1,2],

      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },

      jobDescription : {
        user_id: 0 ,
        job_title: '',
        job_city: '',
        job_type: '',
        job_company: '',
        job_salary_from: 0,
        job_salary_to: 0,
        job_salary_currency: '',
        createFlag: 0,

      },

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],

      showArchive: false,
    }
  },

  mounted() {

    this.getAllVacancies();

  },

  methods: {



    getAllVacancies(){

      this.input_save_vacancy.user_id = store.state.user_id;

      var url = '' + this.$hostname + '/prod/vacancy/get-list?access_token=' + store.state.token;
      console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.allVacancies = res.data.arr;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });

    },




    updateVacancy(e) {
      console.log('inside updateVacancy')
      console.log(e);

      this.getAllVacancies();
    },

  }
}
</script>




<template>

  <div>
    <Navbar :nav-light="true" />
    <section class="section">

      <div class="row" >
        <div class="col">
          <div class="container mt-lg-3">
            <a href="/new-job-edit" class="btn btn-primary"> Создать вакансию </a>
            <!-- <a href="/new-client-edit" class="btn btn-secondary ml-2"> Новый клиент </a> -->
          </div>
        </div>

      </div>

      <div class="container mt-lg-3">


        <div class="row" v-for="(job,ix) in activeVacancies">
          <div class="col">
            <div class="widget rounded">
              <VacancyHighlight :job="job" :index="ix" @index="updateVacancy" />
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-lg-3" v-if="archiveVacancies.length>0">

        <a class="btn btn-warning" @click="showArchive=!showArchive"> Показать архивные </a>
      </div>

      <div class="container mt-lg-3" v-if="showArchive">


        <div class="row" v-for="(job,ix) in archiveVacancies">
          <div class="col">
            <div class="widget rounded">
              <VacancyHighlight :job="job" :index="ix" @index="updateVacancy" />
            </div>
          </div>
        </div>
      </div>


    </section>

    <Footer />

  </div>
</template>
